import axios from "../axios";
import _get from "lodash/get";
import * as cookie from "react-cookie";
import * as TYPES from "./authActionTypes";
import { cookieOptions, getAuthUrl } from "../../constants";

const cookies = new cookie.Cookies();

function removeCookie(key) {
  cookies.remove(key, cookieOptions);
}

export const setProfile = value => ({
  type: TYPES.PROFILE,
  value
});

export const setBusiness = value => ({
  type: TYPES.BUSINESS,
  value
});

export const setAuthToken = value => ({
  type: TYPES.AUTH_TOKEN,
  value
});

export const setTrackerToken = value => ({
  type: TYPES.TRACKER_TOKEN,
  value
});

export const preCheck = value => ({
  type: TYPES.PRE_CHECK,
  value
});

export function signOut() {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        url: `/members/logout`,
        timeout: 20000,
        method: "post",
        data: {}
      });

      const _response = response.data;

      removeCookie("token");
      removeCookie("jwtToken");
      removeCookie("businessId");
      removeCookie("userId");

      dispatch(setProfile(null));
      dispatch(setBusiness(null));
      dispatch(setAuthToken(null));

      window.location.href = getAuthUrl();
      return _response;
    } catch (error) {
      const statusCode = _get(error, "response.status");
      const statusText = _get(error, "response.statusText");
      const message =
        _get(error, "response.data.errors[0].message") ||
        `${statusCode} ${statusText}`;
      return message;
    }
  };
}

export function getProfile(params = {}) {
  return async (dispatch, getState) => {
    const token = cookies.get("token", {
      path: "/"
    });

    if (!token) {
      window.location.href = getAuthUrl();
      dispatch(preCheck(false));
      return;
    }

    try {
      const response = await axios({
        url: `/osritTokens/${token}`,
        method: "get",
        params
      });
      const _response = response.data;

      const resTraccars = await axios({
        url: `/osritTokens/gps`,
        method: "get",
        headers: {
          authorization: token
        }
      });

      const trackerData = _get(resTraccars, "data", {});

      dispatch(setProfile(_response.member));
      dispatch(setBusiness(_response.business));
      dispatch(setAuthToken(token));
      dispatch(setTrackerToken(trackerData));
      dispatch(preCheck(true));

      return _response;
    } catch (e) {
      const _location = window.location;

      if (!_location.pathname.includes("/login")) {
        window.location.href = getAuthUrl();
      }
    }
  };
}
