import _get from "lodash/get";
import * as TYPES from "./authActionTypes";
const defaultState = {
  authBusy: false,
  preCheck: false,
  token: null,
  tracker: null,
  trackerToken: null,
  profile: null,
  driver: null,
  business: null,
  isOwner: false,
  isDispacher: false,
  isGuest: false,
  countLimit: 10,
  hereKeys: {}
};

export default function auth(state = defaultState, action) {
  switch (action.type) {
    case TYPES.PROFILE:
      const userRole = _get(action, "value.role", {});
      const customRole = _get(action, "value.customaccess");
      return {
        ...state,
        isOwner: userRole === "owner" && customRole !== "surrogate",
        isDispacher:
          userRole === "dispacher" ||
          (userRole === "owner" && customRole === "surrogate"),
        isGuest: userRole === "guest",
        countLimit: _get(action, "value.loadsperpage", 10),
        profile: action.value
      };
    case TYPES.FIREBASE_TOKEN:
      return {
        ...state,
        firebaseToken: action.value
      };
    case TYPES.BUSINESS:
      return {
        ...state,
        business: action.value,
        hereKeys: _get(action, "value.hereMap")
      };
    case TYPES.AUTH_TOKEN:
      return {
        ...state,
        token: action.value
      };
    case TYPES.TRACKER_TOKEN:
      return {
        ...state,
        tracker: action.value,
        trackerToken: _get(action, "value.authorization")
      };
    case TYPES.AUTH_BUSY:
      return {
        ...state,
        authBusy: action.value
      };
    case TYPES.PRE_CHECK:
      return {
        ...state,
        preCheck: action.value
      };
    default:
      return state;
  }
}
