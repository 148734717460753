import * as cookie from "react-cookie";

function getDomain() {
  const url = window.location.hostname;
  let hostname = url.split(".").slice(-2);
  return hostname.length === 1 ? "localhost" : `.${hostname.join(".")}`;
}

const URLS = {
  CORE_API_KEY: process.env.REACT_APP_CORE_API_KEY,
  CORE_API: process.env.REACT_APP_CORE_API,
  GPS_API: process.env.REACT_APP_GPS_API,
  NEXT_API: process.env.REACT_APP_NEXT_API,
  TRACKER_API: process.env.REACT_APP_TRACKER_API,
  DC_API: process.env.REACT_APP_DC_API,

  CF_API: process.env.REACT_APP_CF_API,
  CS_API: process.env.REACT_APP_CARRIER_SEARCH_API,

  AUTH_WEB: process.env.REACT_APP_AUTH_WEB,
  NEXT_WEB: process.env.REACT_APP_NEXT_WEB,
  GPS_WEB: process.env.REACT_APP_GPS_WEB,
  COMPLIANCE_WEB: process.env.REACT_APP_COMPLIANCE_WEB,
  DASHCAM_WEB: process.env.REACT_APP_DASHCAM_WEB,
  ACCOUNTING_WEB: process.env.REACT_APP_ACCOUNTING_WEB,
  RECRUITING_WEB: process.env.REACT_APP_RECRUITING_WEB,
  HOME_WEB: process.env.REACT_APP_HOME_WEB
};

const cookies = new cookie.Cookies();

const cookieOptions = {
  path: "/",
  maxAge: 43200,
  domain: getDomain(),
  sameSite: "strict",
  secure: getDomain() !== "localhost"
};

const getAccessToken = function getAccessToken() {
  return cookies.get("token", cookieOptions);
};

const getJwtToken = function getJwtToken() {
  return cookies.get("jwtToken", cookieOptions);
};

const BASE_NAV = {
  SETTING: "/setting",
  DEVICE: "/device",
  GPS: "/gps",
  DASHBOARD: "/dashboard",
  TRIPS: "/trips",
  DASHCAM_TRIPS: "/trips/dashcam",
  GPS_TRIPS: "/trips/gps",

  EVENTS: "/events",
  DASHCAM_EVENTS: "/events/dashcam",
  GPS_EVENTS: "/events/gps"
};

function getAuthUrl() {
  const _location = window.location;
  const buff = new Buffer(_location.href);
  const redirectUrl = buff.toString("base64");
  const authUrl = `${URLS.AUTH_WEB}/login?redirectUrl=${redirectUrl}`;
  return authUrl;
}

export {
  URLS,
  getAccessToken,
  getJwtToken,
  cookieOptions,
  BASE_NAV,
  getAuthUrl
};
