import { combineReducers } from "redux";
import app from "./app/appReducers";
import auth from "./auth/authReducers";
import gpsDevice from "./gps/deviceReducers";
import business from "./business/businessReducers";
import firebase from "./firebase/firebaseReducers";
import device from "./device/deviceReducers";

export default combineReducers({
  appData: app,
  authData: auth,
  deviceGpsData: gpsDevice,
  businessData: business,
  firebaseData: firebase,
  deviceData: device
});
