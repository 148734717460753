import axios from "axios";
import _get from "lodash/get";
import { URLS, getAccessToken } from "../constants";

const axiosInstance = axios.create({
  baseURL: URLS.CORE_API
  /* other custom settings */
});

axiosInstance.defaults.timeout = 10000;
axiosInstance.defaults.headers.put["Content-Type"] = "application/json";
axiosInstance.defaults.headers.post["Content-Type"] = "application/json";
axiosInstance.interceptors.request.use(function requestInterceptor(config) {
  config.headers["authorization"] = getAccessToken();
  config.headers["x-api-key"] = URLS.CORE_API_KEY;
  return config;
});

axiosInstance.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    const responseCode = _get(error, "response.data.errors[0].code");

    if (
      responseCode === "UN_AUTHORIZED" ||
      error.message === "Request failed with status code 401"
    ) {
      // window.location.href = `https://app.osrit.com/login`;
    }

    return Promise.reject(error);
  }
);

const cfUrl = URLS.CF_API;
const appUrl = "https://www.app.osrit.com";
export { getAccessToken, cfUrl, appUrl };
export default axiosInstance;
