import _get from "lodash/get";
import { Alert } from "reactstrap";
import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ReactNotification from "react-notifications-component";
import { Offline } from "react-detect-offline";
import SocketApp from "./SocketApp";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
// const Login = React.lazy(() => import("./views/Pages/Login"));
// const Register = React.lazy(() => import("./views/Pages/Register"));
const Page404 = React.lazy(() => import("./views/Pages/Page404"));
const Page500 = React.lazy(() => import("./views/Pages/Page500"));

// const PaycheckTemplate = React.lazy(() => import("./views/Templates/Paycheck"));
// const InvoiceTemplate = React.lazy(() => import("./views/Templates/Invoice"));
// const NdaTemplate = React.lazy(() => import("./views/Templates/Agreements/Nda"));
// const DriverDetail = React.lazy(() => import("./views/Templates/Agreements/DriverDetail"));
// const DriverRoadTest = React.lazy(() => import("./views/Templates/Agreements/DriverRoadTest"));
// const CertificateRoadTest = React.lazy(() => import("./views/Templates/Agreements/CertificateRoadTest"));
// const CertificationOfViolation = React.lazy(() => import("./views/Templates/Agreements/CertificationOfViolation"));
// const AnnualReviewRecord = React.lazy(() => import("./views/Templates/Agreements/AnnualReviewRecord"));
// const ProficiencyAndAuthorized = React.lazy(() => import("./views/Templates/Agreements/ProficiencyAndAuthorized"));
// const KoolaLogisticsProfile = React.lazy(() => import("./views/Templates/Agreements/KoolaLogisticsProfile"));

class App extends Component {
  componentDidMount() { }

  render() {

    if (!this.props.preCheck) {
      return null;
    }

    return (
      <React.Fragment>
        <Offline>
          <Alert
            style={{ margin: 0, fontSize: 18, fontWeight: 600 }}
            className="text-center text-uppercase"
            color="danger"
          >
            <i className="fa fa-warning" /> You're offline right now. Check your
            internet connection.
          </Alert>
        </Offline>
        <Router>
          <ReactNotification />
          <SocketApp />
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route
                exact
                path="/404"
                name="Page 404"
                render={props => <Page404 {...props} />}
              />
              <Route
                exact
                path="/500"
                name="Page 500"
                render={props => <Page500 {...props} />}
              />
              <Route
                path="/"
                name="Home"
                render={props => <DefaultLayout {...props} />}
              />
            </Switch>
          </React.Suspense>
        </Router>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    preCheck: _get(state, "authData.preCheck", false)
  };
};

export default connect(
  mapStateToProps,
  {}
)(App);
