import axios from "../axiosTrack";
import _get from "lodash/get";
import * as TYPES from "./actionTypes";

export function setDevices(value) {
  return { type: TYPES.GPS_DEVICES, value };
}

export function setPositions(value) {
  return { type: TYPES.GPS_DEVICE_POSITIONS, value };
}

export function setEvents(value) {
  return { type: TYPES.GPS_DEVICE_EVENTS, value };
}

export function setGeofences(value) {
  return { type: TYPES.GPS_GEOFENCES, value };
}

export function setNotifications(value) {
  return { type: TYPES.GPS_NOTIFICATIONS, value };
}

export function getDevices(params) {
  return async (dispatch, getState) => {
    try {
      const trackerToken = _get(getState(), "authData.trackerToken");
      const _deviceResponse = await axios({
        url: `/devices`,
        timeout: 10000,
        method: "get",
        headers: {
          Authorization: `Basic ${trackerToken}`
        },
        params
      });

      const _positionResponse = await axios({
        url: `/positions`,
        timeout: 10000,
        method: "get",
        headers: {
          Authorization: `Basic ${trackerToken}`
        },
        params
      });

      let deviceResponse = _get(_deviceResponse, "data", []);
      let positionResponse = _get(_positionResponse, "data", []);

      deviceResponse = deviceResponse.map(_device => {
        _device.position = positionResponse.find(
          _position => _device.id === _position.deviceId
        );
        return _device;
      });

      dispatch(setDevices(deviceResponse));
      return deviceResponse;
    } catch (error) {
      const statusCode = _get(error, "response.status");
      const statusText = _get(error, "response.statusText");
      const message =
        _get(error, "response.data.errors[0].message") ||
        `${statusCode} ${statusText}`;
      return message;
    }
  };
}

export function createDevice(params) {
  return async (dispatch, getState) => {
    try {
      const trackerToken = _get(getState(), "authData.trackerToken");
      const _response = await axios({
        url: `/devices`,
        timeout: 10000,
        method: "post",
        headers: {
          Authorization: `Basic ${trackerToken}`
        },
        data: params
      });

      let response = _get(_response, "data", {});
      return response;
    } catch (error) {
      const statusCode = _get(error, "response.status");
      const statusText = _get(error, "response.statusText");
      const message =
        _get(error, "response.data.errors[0].message") ||
        `${statusCode} ${statusText}`;
      return message;
    }
  };
}
export function updateDevice(id, params) {
  return async (dispatch, getState) => {
    try {
      const trackerToken = _get(getState(), "authData.trackerToken");
      const _response = await axios({
        url: `/devices/${id}`,
        timeout: 10000,
        method: "put",
        headers: {
          Authorization: `Basic ${trackerToken}`
        },
        data: params
      });

      let response = _get(_response, "data", {});
      return response;
    } catch (error) {
      const statusCode = _get(error, "response.status");
      const statusText = _get(error, "response.statusText");
      const message =
        _get(error, "response.data.errors[0].message") ||
        `${statusCode} ${statusText}`;
      return message;
    }
  };
}
export function getDevice(id, params) {
  return async (dispatch, getState) => {
    try {
      const trackerToken = _get(getState(), "authData.trackerToken");
      const _response = await axios({
        url: `/devices?id=${id}`,
        timeout: 10000,
        method: "get",
        headers: {
          Authorization: `Basic ${trackerToken}`
        },
        data: params
      });

      let response = _get(_response, "data", {});
      return response;
    } catch (error) {
      const statusCode = _get(error, "response.status");
      const statusText = _get(error, "response.statusText");
      const message =
        _get(error, "response.data.errors[0].message") ||
        `${statusCode} ${statusText}`;
      return message;
    }
  };
}

export function assignGeofance(deviceId, geofenceId) {
  return async (dispatch, getState) => {
    try {
      const trackerToken = _get(getState(), "authData.trackerToken");
      const _response = await axios({
        url: `/permissions`,
        timeout: 10000,
        method: "post",
        headers: {
          Authorization: `Basic ${trackerToken}`
        },
        data: { deviceId, geofenceId }
      });

      let response = _get(_response, "data", {});
      return response;
    } catch (error) {
      const statusCode = _get(error, "response.status");
      const statusText = _get(error, "response.statusText");
      const message =
        _get(error, "response.data.errors[0].message") ||
        `${statusCode} ${statusText}`;
      return message;
    }
  };
}

export function deleteGeofance(deviceId, geofenceId) {
  return async (dispatch, getState) => {
    try {
      const trackerToken = _get(getState(), "authData.trackerToken");
      const _response = await axios({
        url: `/permissions`,
        timeout: 10000,
        method: "delete",
        headers: {
          Authorization: `Basic ${trackerToken}`
        },
        data: { deviceId, geofenceId }
      });

      let response = _get(_response, "data", {});
      return response;
    } catch (error) {
      const statusCode = _get(error, "response.status");
      const statusText = _get(error, "response.statusText");
      const message =
        _get(error, "response.data.errors[0].message") ||
        `${statusCode} ${statusText}`;
      return message;
    }
  };
}
export function getPositionsByIds(ids = [], params = {}) {
  return async (dispatch, getState) => {
    try {
      dispatch(setPositions([]));
      if (ids.length === 0) {
        return
      }
      const trackerToken = _get(getState(), "authData.trackerToken");
      let url = `/positions?`
      ids.forEach(element => {
        url = url + `id=${element}&`
      });
      const _response = await axios({
        url: url,
        timeout: 10000,
        method: "get",
        headers: {
          Authorization: `Basic ${trackerToken}`
        },
        params
      });

      let response = _get(_response, "data", []);
      dispatch(setPositions(response));
      return response;
    } catch (error) {
      const statusCode = _get(error, "response.status");
      const statusText = _get(error, "response.statusText");
      const message =
        _get(error, "response.data.errors[0].message") ||
        `${statusCode} ${statusText}`;
      return message;
    }
  };
}

export function getPositions(params) {
  return async (dispatch, getState) => {
    try {
      const trackerToken = _get(getState(), "authData.trackerToken");
      const _response = await axios({
        url: `/positions`,
        timeout: 10000,
        method: "get",
        headers: {
          Authorization: `Basic ${trackerToken}`
        },
        params
      });

      let response = _get(_response, "data", []);
      dispatch(setPositions(response));
      return response;
    } catch (error) {
      const statusCode = _get(error, "response.status");
      const statusText = _get(error, "response.statusText");
      const message =
        _get(error, "response.data.errors[0].message") ||
        `${statusCode} ${statusText}`;
      return message;
    }
  };
}
export function getNotifications(deviceId) {
  return async (dispatch, getState) => {
    try {
      const trackerToken = _get(getState(), "authData.trackerToken");
      const _response = await axios({
        url: `/notifications?deviceId=${deviceId}`,
        timeout: 10000,
        method: "get",
        headers: {
          Authorization: `Basic ${trackerToken}`
        },
        // params
      });

      let response = _get(_response, "data", []);
      dispatch(setNotifications(response));
      return response;
    } catch (error) {
      const statusCode = _get(error, "response.status");
      const statusText = _get(error, "response.statusText");
      const message =
        _get(error, "response.data.errors[0].message") ||
        `${statusCode} ${statusText}`;
      return message;
    }
  };
}

export function getEvents(params) {
  return async (dispatch, getState) => {
    try {
      dispatch(setEvents([]));
      const trackerToken = _get(getState(), "authData.trackerToken");
      const _response = await axios({
        url: `/reports/events`,
        // groupId=15&type=allEvents&from=2020-05-21T07%3A00%3A00.000Z&to=2020-05-22T07%3A00%3A00.000Z&page=1&start=0&limit=25
        // type=deviceOverspeed&type=alarm&type=deviceOffline&type=ignitionOff&type=geofenceExit&type=deviceMoving&type=geofenceEnter&type=deviceOnline&type=deviceStopped&type=ignitionOn
        timeout: 10000,
        method: "get",
        headers: {
          Authorization: `Basic ${trackerToken}`
        },
        params
      });

      let response = _get(_response, "data", []);
      dispatch(setEvents(response));
      return response;
    } catch (error) {
      const statusCode = _get(error, "response.status");
      const statusText = _get(error, "response.statusText");
      const message =
        _get(error, "response.data.errors[0].message") ||
        `${statusCode} ${statusText}`;
      return message;
    }
  };
}

export function getRoutes(params) {
  return async (dispatch, getState) => {
    try {
      const trackerToken = _get(getState(), "authData.trackerToken");
      const _response = await axios({
        url: `/reports/route`,
        // groupId=15&type=allEvents&from=2020-05-21T07%3A00%3A00.000Z&to=2020-05-22T07%3A00%3A00.000Z&page=1&start=0&limit=25
        timeout: 10000,
        method: "get",
        headers: {
          Authorization: `Basic ${trackerToken}`
        },
        params
      });

      let response = _get(_response, "data", []);
      return response;
    } catch (error) {
      const statusCode = _get(error, "response.status");
      const statusText = _get(error, "response.statusText");
      const message =
        _get(error, "response.data.errors[0].message") ||
        `${statusCode} ${statusText}`;
      return message;
    }
  };
}

export function getStops(params) {
  return async (dispatch, getState) => {
    try {
      const trackerToken = _get(getState(), "authData.trackerToken");
      const _response = await axios({
        url: `/reports/stops`,
        // groupId=15&type=allEvents&from=2020-05-21T07%3A00%3A00.000Z&to=2020-05-22T07%3A00%3A00.000Z&page=1&start=0&limit=25
        timeout: 10000,
        method: "get",
        headers: {
          Authorization: `Basic ${trackerToken}`
        },
        params
      });

      let response = _get(_response, "data", []);
      return response;
    } catch (error) {
      const statusCode = _get(error, "response.status");
      const statusText = _get(error, "response.statusText");
      const message =
        _get(error, "response.data.errors[0].message") ||
        `${statusCode} ${statusText}`;
      return message;
    }
  };
}

export function getSummary(params) {
  return async (dispatch, getState) => {
    try {
      const trackerToken = _get(getState(), "authData.trackerToken");
      const _response = await axios({
        url: `/reports/summary`,
        // groupId=15&type=allEvents&from=2020-05-21T07%3A00%3A00.000Z&to=2020-05-22T07%3A00%3A00.000Z&page=1&start=0&limit=25
        timeout: 10000,
        method: "get",
        headers: {
          Authorization: `Basic ${trackerToken}`
        },
        params
      });

      let response = _get(_response, "data", []);
      return response;
    } catch (error) {
      const statusCode = _get(error, "response.status");
      const statusText = _get(error, "response.statusText");
      const message =
        _get(error, "response.data.errors[0].message") ||
        `${statusCode} ${statusText}`;
      return message;
    }
  };
}

export function getTrips(params) {
  return async (dispatch, getState) => {
    try {
      const trackerToken = _get(getState(), "authData.trackerToken");
      const _response = await axios({
        url: `/reports/trips`,
        // groupId=15&type=allEvents&from=2020-05-21T07%3A00%3A00.000Z&to=2020-05-22T07%3A00%3A00.000Z&page=1&start=0&limit=25
        timeout: 10000,
        method: "get",
        headers: {
          Authorization: `Basic ${trackerToken}`
        },
        params
      });

      let response = _get(_response, "data", []);
      return response;
    } catch (error) {
      const statusCode = _get(error, "response.status");
      const statusText = _get(error, "response.statusText");
      const message =
        _get(error, "response.data.errors[0].message") ||
        `${statusCode} ${statusText}`;
      return message;
    }
  };
}

export function getGeofences(deviceId) {
  return async (dispatch, getState) => {
    try {
      let url = "/geofences"
      if (deviceId) {
        url = url + "?deviceId=" + deviceId
      }
      const trackerToken = _get(getState(), "authData.trackerToken");
      const _response = await axios({
        url: url,
        timeout: 10000,
        method: "get",
        headers: {
          Authorization: `Basic ${trackerToken}`
        }
      });

      let response = _get(_response, "data", []);
      dispatch(setGeofences(response));
      return response;
    } catch (error) {
      const statusCode = _get(error, "response.status");
      const statusText = _get(error, "response.statusText");
      const message =
        _get(error, "response.data.errors[0].message") ||
        `${statusCode} ${statusText}`;
      return message;
    }
  };
}
export function createGeofence(params) {
  return async (dispatch, getState) => {
    try {
      const trackerToken = _get(getState(), "authData.trackerToken");
      const _response = await axios({
        url: `/geofences`,
        timeout: 10000,
        method: "post",
        headers: {
          Authorization: `Basic ${trackerToken}`
        },
        data: params
      });

      let response = _get(_response, "data", {});
      return response;
    } catch (error) {
      const statusCode = _get(error, "response.status");
      const statusText = _get(error, "response.statusText");
      const message =
        _get(error, "response.data.errors[0].message") ||
        `${statusCode} ${statusText}`;
      return message;
    }
  };
}
export function updateGeofence(params) {
  return async (dispatch, getState) => {
    try {
      const trackerToken = _get(getState(), "authData.trackerToken");
      const _response = await axios({
        url: `/geofences/${params.id}`,
        timeout: 10000,
        method: "put",
        headers: {
          Authorization: `Basic ${trackerToken}`
        },
        data: params
      });

      let response = _get(_response, "data", {});
      return response;
    } catch (error) {
      const statusCode = _get(error, "response.status");
      const statusText = _get(error, "response.statusText");
      const message =
        _get(error, "response.data.errors[0].message") ||
        `${statusCode} ${statusText}`;
      return message;
    }
  };
}
