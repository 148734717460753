import * as TYPES from "./appActionTypes";

function getDomain() {
  let hostname = window.location.hostname;
  return hostname === "localhost"
    ? "osrit"
    : hostname.split(".").slice(-2, -1)[0];
}

const defaultState = {
  authBusy: false,
  baseUrl: "https://osritapi.com/api",
  documentUrl: `https://agreements.${getDomain()}.com`
};

export default function app(state = defaultState, action) {
  switch (action.type) {
    case TYPES.APP_BUSY:
      return {
        ...state,
        appBusy: action.value
      };
    default:
      return state;
  }
}
