import "react-app-polyfill/ie9"; // For IE 9-11 support
import "react-app-polyfill/stable";
// import 'react-app-polyfill/ie11'; // For IE 11 support
import "./polyfill";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import configureStore from "./configureStore";
import * as serviceWorker from "./serviceWorker";
import { registerServiceWorker } from "./notification/register-sw";
import * as Sentry from "@sentry/browser";

import "./App.scss";
import "./index.css";
import "react-table/react-table.css";
import "react-notifications-component/dist/theme.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-datepicker/dist/react-datepicker.css";
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css'
import "leaflet-draw/dist/leaflet.draw-src.css";
import H from "@here/maps-api-for-javascript";

window.H = H;
const store = configureStore();
const sentryDns = process.env.REACT_APP_SENTRY_DNS

if (process.env.NODE_ENV === "production" && sentryDns) {
  console.log(" **** SENTRY INIT **** ", process.env.NODE_ENV);
  Sentry.init({
    dsn: sentryDns
  });
}

class AppBootstrap extends Component {
  render() {
    return (
      <Provider store={store}>
        <App />
      </Provider>
    );
  }
}

registerServiceWorker();

ReactDOM.render(<AppBootstrap />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
