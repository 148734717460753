import * as TYPES from "./deviceActionTypes";

const defaultState = {
  devices: [],
  trips: [],
  events: [],
  images: [],
  videos: []
};

export default function device(state = defaultState, action) {
  switch (action.type) {
    case TYPES.DEVICES:
      return {
        ...state,
        devices: action.value
      };
    case TYPES.EVENTS:
      return {
        ...state,
        events: action.value
      };
    case TYPES.TRIPS:
      return {
        ...state,
        trips: action.value
      };
    case TYPES.IMAGES:
      return {
        ...state,
        images: action.value
      };
    case TYPES.VIDEOS:
      return {
        ...state,
        videos: action.value
      };
    case TYPES.BASE_URL:
      return {
        ...state,
        fileBaseUrl: action.value
      };
    default:
      return state
  }
}
