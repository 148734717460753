// import _get from "lodash/get";
import * as TYPES from "./actionTypes";
const defaultState = {
  deviceBusy: false,
  count: 0,
  devices: [],
  deviceEvents: [],
  devicePositions: [],
  geofences: [],
  notifications: []
};

export default function gps(state = defaultState, action) {
  switch (action.type) {
    case TYPES.GPS_DEVICES_BUSY:
      return {
        ...state,
        deviceBusy: action.value
      };
    case TYPES.GPS_DEVICES_COUNT:
      return {
        ...state,
        count: action.value
      };
    case TYPES.GPS_DEVICES:
      return {
        ...state,
        devices: action.value
      };
    case TYPES.GPS_DEVICE_POSITIONS:
      return {
        ...state,
        devicePositions: action.value
      };
    case TYPES.GPS_DEVICE_EVENTS:
      return {
        ...state,
        deviceEvents: action.value
      };
    case TYPES.GPS_GEOFENCES:
      return {
        ...state,
        geofences: action.value
      };
    case TYPES.GPS_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.value
      };
    default:
      return state;
  }
}
